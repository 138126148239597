export function useUser() {
  const {
    $i18n: { t },
  } = useNuxtApp()

  return {
    contractTypeMap: {
      retail: t('components.navbar.customer'),
      profi: 'Profi',
      flexi: 'Flexi',
      easy: 'Easy',
    },
  }
}
